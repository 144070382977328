<template>
  <div style="background-color: #fff; height: 100vh">
    <!-- Your HTML content goes here -->
    <Header />
    <div id="center">
      <div id="box-cont">
        <h1>{{ monthName }} Sticker</h1>
        <h2>Utah State Park Annual Passes</h2>
        <p>
          Purchase a January sticker for your annual pass for a full calendar
          year admittance into any Utah State Park.
        </p>
        <div id="shopifyCollectionWrap" class="shopifyCollectionWrap">
          <div
            id="collection-component-1616176464282"
            class="collectionDiv"
          ></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/SocialFooter";
import $ from "jquery";

export default {
  name: "StickerLanding",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      order_details: {},
      monthName: "",
      customer: {},
      collectionTable: [
        {
          month: "January",
          collectionId: "333714751641",
          seniors: "333784973465",
        },
        {
          month: "February",
          collectionId: "333784514713",
          seniors: "333785006233",
        },
        {
          month: "March",
          collectionId: "333784547481",
          seniors: "333785071769",
        },
        {
          month: "April",
          collectionId: "333784580249",
          seniors: "333785137305",
        },
        {
          month: "May",
          collectionId: "333784613017",
          seniors: "333785137305",
        },
        {
          month: "June",
          collectionId: "333784678553",
          seniors: "333785202841",
        },
        {
          month: "July",
          collectionId: "333784711321",
          seniors: "333785235609",
        },
        {
          month: "August",
          collectionId: "333784776857",
          seniors: "333785399449",
        },
        {
          month: "September",
          collectionId: "333784809625",
          seniors: "333785432217",
        },
        {
          month: "October",
          collectionId: "333784842393",
          seniors: "333785497753",
        },
        {
          month: "November",
          collectionId: "333784940697",
          seniors: "333785563289",
        },
        {
          month: "December",
          collectionId: "334596145305",
          seniors: "334596112537",
        },
      ],
      collectionId: "",
    };
  },
  created() {
    this.order_details = JSON.parse(localStorage.getItem("order_details"));
    this.order_details = this.order_details.tickets[0];
    console.log(this.order_details);

    const customer = JSON.parse(localStorage.getItem("customer"));
    console.log(customer);
    this.customer = customer;

    this.getExpiration();
    this.loadShopifyCollection();
  },
  methods: {
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.ShopifyBuyInit(this.collectionId);
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.ShopifyBuyInit(this.collectionId);
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
    ShopifyBuyInit: function (collectionId) {
      // this.initDivWatcher();
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("collection", {
          id: collectionId,
          node: document.getElementById("collection-component-1616176464282"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    width: "calc(25% - 20px)",
                  },
                  img: {
                    height: "calc(100% - 15px)",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                  },
                  imgWrapper: {
                    "padding-top": "calc(75% + 15px)",
                    position: "relative",
                    height: "0",
                  },
                },
                title: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "normal",
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                price: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                compareAt: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                unitPrice: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
              },
              buttonDestination: "checkout",
              text: {
                button: "Buy now",
              },
              googleFonts: ["Roboto"],
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              popup: false,
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#96a145",
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  ":focus": {
                    "background-color": "#87913e",
                  },
                },
              },
            },
          },
        });
      });
    },
    getExpiration() {
      const expirationDate = new Date(this.order_details.date_expires);
      const lastDayOfMonth = new Date(
        expirationDate.getFullYear(),
        expirationDate.getMonth() + 1,
        0
      );
      const lastMonth = lastDayOfMonth.getMonth() + 1;
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[lastMonth - 1];
      console.log(lastMonth, monthName);
      this.monthName = monthName;
      for (var i in this.collectionTable) {
        if (this.collectionTable[i].month == monthName) {
          this.collectionId = this.collectionTable[i].collectionId;
          // if senior get the senior pass
        }
        if (this.customer.senior == "true") {
          this.collectionId = this.collectionTable[i].seniors;
        }
      }
    },
  },
  // Your component's logic goes here
};
</script>

<style scoped>
#hero {
  background-image: url("../../assets/images/pickup-big.png");
  height: 380px;
  background-size: cover;
  align-content: center;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 10px;
}
p {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 50px;
}
#box-cont {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
}
#center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#grey-box {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  width: 60%;
  padding: 25px;
  margin-bottom: 100px;
}
#radio-cont {
  display: flex;
  margin-top: 20px;
  flex-direction: row !important;
}
.radio {
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px;
  margin-right: 10px;
}
.radio > label {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.radio-item {
  margin-right: 20px;
}
.circle {
  margin-right: 10px;
}
.radio-text {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}
.side-flex {
  display: flex;
  justify-content: space-between;
}
.down-flex {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.personal-input {
  width: 100%;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  padding-left: 16px;
  border-bottom: 1px solid #8f8f8f;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 20px;
}
h3 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
textarea:focus,
input:focus {
  outline: none;
}
.green-button {
  border-radius: 23px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 10px 18px;
}
#button-flex {
  display: flex;
  justify-content: flex-end;
}
/* Your component's styles go here */
@media (max-width: 425px) {
  /* Your styles for screens smaller than 425px go here */
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 18px;
  }
  #center {
    padding: 0px 24px;
  }
}
</style>
<style>
.v-input--radio-group__input {
  width: unset !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  padding-left: 10px;
}
</style>
