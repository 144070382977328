export const parkPassesJson = [
  // Anasazi State Park
  {
    name: "Anasazi State Park Museum",
    passType: "Family",
    altDescription: "Buy your Family Anasazi State Park Museum Pass here",
    image: "family.png",
    description:
      "Day-Use Pass: All member benefits for two adults and up to 6 children, or up to 6 grandchildren, 18 years or under.",
    price: "10.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Anasazi State Park Museum",
    passType: "Individual",
    altDescription: "Buy your Individual Anasazi State Park Museum Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Anasazi State Park Museum",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior resident Anasazi State Park Museum Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per individual 65 and older Utah resident.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    resident: true,
  },

  // Antelope Island State Park
  {
    name: "Antelope Island State Park",
    passType: "Vehicle Regular",
    altDescription:
      "Buy your Vehicle Regular Antelope Island State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Antelope Island State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident Antelope Island State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Antelope Island State Park",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Buy your Pedestrian/Cyclist Antelope Island State Park Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Antelope Island State Park",
    passType: "Commercial Passenger",
    altDescription:
      "Buy your Commercial Passenger Antelope Island State Park Pass here",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Antelope Island State Park",
    passType: "Commercial Vehicle",
    altDescription:
      "Buy your Commercial Vehicle Antelope Island State Park Pass here",
    image: "com-vehicle.png",
    description: "Day-Use Pass: per vehicle over 8 people.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Antelope Island State Park",
    passType: "Motorcycle",
    altDescription: "Buy your Motorcycle Antelope Island State Park Pass here",
    image: "motorcycle.png",
    description: "Day-Use Pass: per motorcycle",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  // Bear Lake Peak Season (In season: MAY 1 - OCT 21)
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Utah Vehicle",
    altDescription: "Buy your Utah Vehicle  Bear Lake State Park Pass here",
    image: "vehicle.png",
    description:
      "In-Season Regular: In-Season Regular: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Non Resident Vehicle",
    altDescription:
      "Buy your Non-Resident Vehicle Bear Lake State Park Pass here",
    image: "vehicle.png",
    description:
      "In-Season Non Resident: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Utah Senior (65+) Vehicle",
    altDescription:
      "Buy your Utah Senior Vehicle Bear Lake State Park Pass here",
    image: "senior.png",
    description:
      "In-Season Senior: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Utah Motorcycle",
    altDescription: "Buy your Utah Motorcycle Bear Lake State Park Pass here",
    image: "motorcycle.png",
    description: "In-Season Motorcycle: May 1-Oct 31.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Non Resident Motorcycle",
    altDescription:
      "Buy your Non-Resident Motorcycle Bear Lake State Park Pass here",
    image: "motorcycle.png",
    description: "In-Season Non Resident Motorcycle: May 1-Oct 31.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },

  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "vehicle.png",
    description:
      "In-Season Regular: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "vehicle.png",
    description:
      "In-Season Non Resident: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Day Pass",
    image: "motorcycle.png",
    description: "In-Season Motorcycle: May 1-Oct 31.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Day Pass",
    image: "motorcycle.png",
    description: "In-Season Non Resident Motorcycle: May 1-Oct 31.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Walk-in & Bicycle",
    altDescription: "Bear Lake Day Pass",
    image: "individual.png",
    description: "In-Season: Walk-In & Bicycle. May 1-Oct 31.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "senior.png",
    description:
      "In-Season: May 1-Oct 31. Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },

  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "vehicle.png",
    description:
      "In-Season Regular: May 1-Oct 31. First Point, South Eden, Cisco Beach, Rainbow Cove, and North Eden. Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "vehicle.png",
    description:
      "In-Season Non Resident: May 1-Oct 31. First Point, South Eden, Cisco Beach, Rainbow Cove, and North Eden. Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Day Pass",
    image: "senior.png",
    description:
      "In-Season Utah Senior: May 1-Oct 31. First Point, South Eden, Cisco Beach, Rainbow Cove, and North Eden. Day-Use Pass: per vehicle up to 8 people.",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Day Pass",
    image: "motorcycle.png",
    description:
      "In-Season Utah Motorcycle: May 1-Oct 31. First Point, South Eden, Cisco Beach, Rainbow Cove, and North Eden. Day-Use Pass.",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Day Pass",
    image: "motorcycle.png",
    description:
      "In-Season Non Resident Motorcycle: May 1-Oct 31. First Point, South Eden, Cisco Beach, Rainbow Cove, and North Eden. Day-Use Pass.",
    price: "12.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "10/31",
  },

  // Bear Lake Prime Days (Memorial Day through Labor Day)
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Prime Days - Utah Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "vehicle.png",
    description:
      "Prime Days: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Prime Days - Non Resident Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "vehicle.png",
    description:
      "Prime Days Non Resident: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Prime Days - Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "senior.png",
    description:
      "Prime Days: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Prime Days - Utah Motorcycle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "motorcycle.png",
    description:
      "Prime Days Non Resident: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Prime Days - Non Resident Motorcycle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "motorcycle.png",
    description:
      "Prime Days Non-Resident-Motorcycle: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },

  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Prime Days - Utah Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "vehicle.png",
    description:
      "Prime Days: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Prime Days - Non Resident Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "vehicle.png",
    description:
      "Prime Days Non Resident: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Prime Days - Utah Motorcycle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "motorcycle.png",
    description:
      "Prime Days Motorcycle: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Prime Days - Non Resident Motorcycle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "motorcycle.png",
    description:
      "Prime Days Non Resident Motorcycle: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },
  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "Prime Days - Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Prime Day Pass",
    image: "senior.png",
    description:
      "Prime Days: Fridays, Saturdays, Sundays & Holidays - Memorial Day weekend through Labor Day:Day Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/30",
    end_date: "09/05",
  },

  // Bear Lake Off Season (Nov-Apr)
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Non Resident: Day-Use Pass: per vehicle up to 8 people.",
    price: "12.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "senior.png",
    description: "Senior: 65 and older.",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: "Motorcycle:",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: "Non Resident Motorcycle: ",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },

  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "CLOSED",
    altDescription: "Bear Lake Rendezvous Closed",
    image: "close.png",
    description: "The Rendezvous side of Bear Lake is closed until May.",
    price: "",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },

  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "First Point, Cisco Beach, and Rainbow Cove.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "senior.png",
    description:
      "Senior. First Point, Cisco Beach, and Rainbow Cove. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Non Resident. First Point, Cisco Beach, and Rainbow Cove.",
    price: "9.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: " Motorcycle. First Point, Cisco Beach, and Rainbow Cove.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description:
      "Non Resident Motorcycle. First Point, Cisco Beach, and Rainbow Cove.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },

  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Non Resident: Day-Use Pass: per vehicle up to 8 people.",
    price: "12.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "senior.png",
    description: "Senior: 65 and older.",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: "Motorcycle: ",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Marina",
    season: "Off-Season",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: "Non Resident Motorcycle",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },

  {
    name: "Bear Lake State Park",
    location: "Rendezvous",
    passType: "CLOSED",
    altDescription: "Bear Lake Rendezvous Closed",
    image: "close.png",
    description: "The Rendezvous side of Bear Lake is closed until May.",
    price: "00.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },

  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "First Point, Cisco Beach, and Rainbow Cove.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Senior (65+) Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "senior.png",
    description: "First Point, Cisco Beach, and Rainbow Cove. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Non Resident Vehicle",
    altDescription: "Bear Lake Off Season Pass",
    image: "vehicle.png",
    description: "Non Resident. First Point, Cisco Beach, and Rainbow Cove.",
    price: "9.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Utah Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description: "Motorcycle. First Point, Cisco Beach, and Rainbow Cove.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    season: "Off-Season",
    passType: "Non Resident Motorcycle",
    altDescription: "Bear Lake Off Season Pass",
    image: "motorcycle.png",
    description:
      "Non Resident Motorcycle. First Point, Cisco Beach, and Rainbow Cove.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },

  // Bear Lake Year Round
  {
    name: "Bear Lake State Park",
    location: "Marina",
    passType: "Walk-in & Bicycle",
    altDescription:
      "Marina Bear Lake Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Year Round: Walk-In & Bicycle.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Bear Lake State Park",
    location: "Eastside",
    passType: "Walk-in & Bicycle",
    altDescription:
      "Eastside Bear Lake Buy a Utah State Park Pass Biking or Walking ",
    image: "individual.png",
    description: "Year Round: Walk-In & Bicycle.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },

  {
    name: "Camp Floyd State Park Museum",
    passType: "Person",
    altDescription:
      "Buy your Individual Camp Floyd State Park Museum Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per person",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Camp Floyd State Park Museum",
    passType: "Family",
    altDescription: "Buy your Family Camp Floyd State Park Museum Pass here",
    image: "family.png",
    description:
      "Day-Use Pass: All member benefits for two adults and up to 6 children, or up to 6 grandchildren, 18 years or under.",
    price: "10.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Coral Pink Sand Dunes State Park",
    passType: "Vehicle",
    altDescription:
      "Buy your Vehicle Coral Pink Sand Dunes State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Coral Pink Sand Dunes State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident Coral Pink Sand Dunes State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },

  {
    name: "Dead Horse Point State Park",
    passType: "Vehicle",
    altDescription: "Buy your Vehicle Dead Horse Point State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "20.00",
    days: 2,
    plate: true,
    personCount: false,
  },
  {
    name: "Dead Horse Point State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident (65+) Dead Horse Point State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "15.00",
    days: 2,
    plate: true,
    personCount: false,
    resident: true,
  },
  //   {"name":"Dead Horse Point State Park", "passType": "Motorcycles", "altDescription":"Buy your Motorcycles Dead Horse Point State Park Pass here", "image": "motorcycle.png", "description": "2 Consecutive Day-Use Pass: per individual.", "price": "10.00", "days": 2, "plate": false, "personCount": false},
  {
    name: "Dead Horse Point State Park",
    passType: "Pedestrian / Cyclist / Motorcyclist",
    altDescription:
      "Buy your Pedestrian / Cyclist / Motorcyclist Dead Horse Point State Park Pass here",
    image: "individual.png",
    description:
      "Day-Use Pass: per individual. Parking outside the park is limited and the road is very narrow into the park. Guest must park their vehicle outside of the park and enter on foot or by bike.",
    price: "10.00",
    days: 2,
    plate: false,
    personCount: false,
  },
  {
    name: "Dead Horse Point State Park",
    passType: "Commercial Passenger",
    altDescription:
      "Buy your Commercial Passenger Dead Horse Point State Park Pass here",
    image: "com-passenger.png",
    description: "Day-Use Pass: per vehicle over 8 people.",
    price: "5.00",
    days: 2,
    plate: false,
    personCount: true,
  },

  // Deer creek winter
  {
    name: "Deer Creek State Park",
    passType: "Winter Vehicle",
    altDescription: "Buy your Vehicle Weekday Deer Creek State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "10/15",
    end_date: "12/31",
  },
  {
    name: "Deer Creek State Park",
    passType: "Winter Vehicle",
    altDescription: "Buy your Vehicle Weekday Deer Creek State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/15",
  },
  // Deer creek peak season
  {
    name: "Deer Creek State Park",
    passType: "Vehicle Weekday",
    altDescription: "Buy your Vehicle Weekday Deer Creek State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. Mon-Fri.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/15",
    end_date: "10/15",
  },
  {
    name: "Deer Creek State Park",
    passType: "Vehicle Weekend & Holiday",
    altDescription:
      "Buy your Vehicle Weekend & Holiday Deer Creek State Park Pass here",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Sat-Sun & Holidays.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/15",
    end_date: "10/15",
  },
  {
    name: "Deer Creek State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident (65+) Deer Creek State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
    start_date: "01/01",
    end_date: "12/31",
  },
  // {
  //   name: "Deer Creek State Park",
  //   passType: "Pedestrian / Cyclist",
  //   altDescription:
  //     "Buy your Pedestrian / Cyclist Deer Creek State Park Pass here",
  //   image: "individual.png",
  //   description: "Day-Use Pass: per individual.",
  //   price: "10.00",
  //   days: 1,
  //   plate: false,
  //   personCount: false,
  //   start_date: "01/01",
  //   end_date: "12/31",
  // },
  // {
  //   name: "Deer Creek State Park",
  //   passType: "Commercial Passenger",
  //   altDescription: "Buy your Commercial Passenger Echo State Park Pass here",
  //   image: "com-passenger.png",
  //   description: "Day-Use Pass: per individual.",
  //   price: "10.00",
  //   days: 1,
  //   plate: false,
  //   personCount: false,
  //   start_date: "01/01",
  //   end_date: "12/31",
  // },

  {
    name: "East Canyon State Park",
    passType: "Vehicle Mon-Thur",
    altDescription: "Buy your Vehicle East Canyon State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "East Canyon State Park",
    passType: "Vehicle Fri-Sun & Holidays",
    altDescription: "Buy your Vehicle East Canyon State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  //in season 4/1-10/31
  {
    name: "Echo State Park",
    passType: "Private Vehicles (Up to 8 passengers)",
    altDescription: "Buy your Private Vehicle Echo State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Echo State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy your Senior Resident (65+) Echo State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  // all year round
  {
    name: "Echo State Park",
    passType: "Bicycles & Walk-In",
    altDescription: "Buy your bicycle & Walk-In Echo State Park Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per person",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Echo State Park",
    passType: "Commercial Passenger",
    altDescription: "Buy your Commercial Passenger Echo State Park Pass here",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per person of vehicles with more than 8 people.",
    price: "2.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  //off season passes
  {
    name: "Echo State Park",
    passType: "Private Vehicles (Up to 8 passengers)",
    altDescription: "Buy your Private Vehicle Echo State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Echo State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy your Senior Resident (65+) Echo State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Echo State Park",
    passType: "Private Vehicles (Up to 8 passengers)",
    altDescription: "Buy your Private Vehicle Echo State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "03/31",
  },
  {
    name: "Echo State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy your Senior Resident (65+) Echo State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "03/31",
  },
  //camping
  {
    name: "Echo State Park",
    passType: "Camping",
    altDescription: "Buy your Camping for Echo State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass and Campground Fee per day (includes 1 vehicle). All sites have power, water, and no sewer.",
    price: "40.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "10/01",
    end_date: "12/31",
  },
  {
    name: "Echo State Park",
    passType: "Camping",
    altDescription: "Buy your Camping for Echo State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass and Campground Fee per day (includes 1 vehicle). All sites have power, water, and no sewer.",
    price: "40.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "5/01",
  },

  {
    name: "Edge Of The Cedars State Park Museum",
    passType: "Adult",
    altDescription:
      "Buy your Adult Edge Of The Cedars State Park Museum Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Edge Of The Cedars State Park Museum",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident (65+) Edge Of The Cedars State Park Museum Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per individual 65 and older Utah resident.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    resident: true,
  },
  {
    name: "Edge Of The Cedars State Park Museum",
    passType: "Child",
    altDescription:
      "Buy your Child Edge Of The Cedars State Park Museum Pass here",
    image: "child.png",
    description: "Day-Use Pass: per individual. Five and under free.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Escalante Petrified Forest State Park",
    passType: "Vehicle",
    altDescription: "Buy your Vehicle Escalante Hiking State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Escalante Petrified Forest State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Escalante Reservoir & Hiking State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },

  {
    name: "Flight Park State Recreation Area",
    passType: "Camping (Includes 1 Vehicle)",
    altDescription: "Pay your Flight Park camping Pass here",
    image: "camping.png",
    description: "Camping: per night. Includes 1 vehicle.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Flight Park State Recreation Area",
    passType: "Camping - Extra Vehicle",
    altDescription: "Pay your Flight Park extra vehicle Pass here",
    image: "vehicle.png",
    description: "Camping Pass: per extra vehicle",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Fred Hayes State Park at Starvation",
    passType: "Vehicle",
    altDescription: "Buy a Vehicle pass for Fred Hayes State Park Resivior",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Fremont Indian State Park and Museum",
    passType: "Vehicle",
    altDescription: "Buy a Fremont Indian State Park and Museum Vehicle Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Fremont Indian State Park and Museum",
    passType: "Hikers / Bikers",
    altDescription:
      "Buy a Fremont Indian State Park and Museum Hiking or Biking Pass",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Frontier Homestead State Park Museum",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Buy a Frontier Holmstead State Park Cedar City Utah Biking or Walking pass",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "4.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Frontier Homestead State Park Museum",
    passType: "Children Under 5",
    altDescription:
      "Buy a Child Pass for Frontier State Park History Museum Activity",
    image: "child.png",
    description: " Free Year Round",
    price: "Free",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Frontier Homestead State Park Museum",
    passType: "Old Iron Town Donation",
    altDescription: "Donate to Iron Town Utah Monument Iron County",
    image: "donation.png",
    description: "Donate $5 or add a custom donation. ",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Goblin Valley State Park",
    passType: "Vehicle",
    altDescription: "Buy a Goblin Valley State Park Pass Utah Vehicle",
    image: "vehicle.png",
    description: "2 Day-Use Pass: per vehicle up to 8 people",
    price: "20.00",
    days: 2,
    plate: true,
    personCount: false,
  },
  // {"name":"Goblin Valley State Park", "passType": "Senior Resident (65+)", "altDescription":"Buy a Goblin Valley State Park Pass Utah Senior Deal", "image": "senior.png", "description": "2 Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.", "price": "10.00", "days": 2, "plate": false, "personCount": false, "resident": true},
  // {
  //   name: "Goblin Valley State Park",
  //   passType: "Pedestrian / Cyclist / Motorcycle",
  //   altDescription:
  //     "Buy a Utah State Park Pass Biking, Motorcycle, Walking Goblin Valley",
  //   image: "individual.png",
  //   description: "2 Day-Use Pass: per group up to 8 people",
  //   price: "10.00",
  //   days: 2,
  //   plate: false,
  //   personCount: false,
  // },
  // {
  //   name: "Goblin Valley State Park",
  //   passType: "Commercial Passenger",
  //   altDescription: "Buy a Utah State Park Pass Tour Group Passenger",
  //   image: "com-passenger.png",
  //   description:
  //     "Day-Use Pass: per individual of vehicles with more than 8 people.",
  //   price: "5.00",
  //   days: 2,
  //   plate: false,
  //   personCount: false,
  // },

  {
    name: "Goosenecks State Park",
    passType: "Vehicle",
    "Buy a Utah State Park Pass Goosenecks Hiking Driving":
      "Buy a Utah State Park Pass Goosenecks",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Goosenecks State Park",
    passType: "Commercial Passenger",
    "Buy a Utah State Park Pass Tour Group Passenger Goosenecks":
      "Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "2.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Goosenecks State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy a Utah State Park Pass Senior Deal Goosenecks",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "2.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Great Salt Lake State Park",
    passType: "Vehicle",
    altDescription: "Buy a Utah State Park Pass The Great Salt Lake ",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Great Salt Lake State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy a Utah State Park Pass Senior Deal The Great Salt Lake",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "3.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Great Salt Lake State Park",
    passType: "Commercial Vehicle",
    altDescription:
      "Buy a Utah State Park Pass Tour Group Passenger The Great Salt Lake",
    image: "com-vehicle.png",
    description: "Day-Use Pass: per vehicle over 8 people",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: true,
  },
  {
    name: "Great Salt Lake State Park",
    passType: "Bicycle",
    altDescription:
      "Buy a Utah State Park Pass Tour Group Passenger The Great Salt Lake",
    image: "bicycle.svg",
    description: "Day-Use Pass: per individual",
    price: "2.00",
    days: 1,
    plate: true,
    personCount: true,
  },

  {
    name: "Green River State Park",
    passType: "Vehicle Resident",
    altDescription: "Green River Buy a Utah State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Green River State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Green River Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Green River State Park",
    passType: "Bicycle / Pedestrian Resident",
    altDescription:
      "Green River Buy a Utah State Park Pass Biking or Walking Local",
    image: "individual.png",
    description: "Day-Use Pass: per individual.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Green River State Park",
    passType: "Vehicle Non-Resident",
    "Green River Buy a Utah State Park Pass Tourist": "Green River",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Green River State Park",
    passType: "Senior Non-Resident (65+)",
    altDescription: "Green River Buy a Utah State Park Pass Tourist Senior",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "7.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Green River State Park",
    passType: "Bicycle / Pedestrian Non-Resident",
    altDescription:
      "Green River Buy a Utah State Park Pass Biking or Walking Tourist",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Green River State Park",
    passType: "Commercial Passenger",
    altDescription:
      "Green River Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per person of vehicles with more than 8 people. ",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Gunlock State Park",
    passType: "Vehicle Resident",
    altDescription: "Gunlock Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Gunlock State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Gunlock Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Gunlock State Park",
    passType: "Vehicle Non-Resident",
    altDescription: "Gunlock Buy a Utah State Park Pass Visiting",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Huntington State Park",
    passType: "Vehicle",
    altDescription: "Huntington State Park Buy a Utah State Park Pass Vehicle",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Huntington State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Huntington State Park Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Hyrum State Park",
    passType: "Vehicle Weekday",
    altDescription: "Buy a Utah State Park Pass Weekday",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. Mon-Fri, 6AM-10PM.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Hyrum State Park",
    passType: "Vehicle Weekend & Holiday",
    altDescription: "Buy a Utah State Park Pass Weekend",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Sat-Sun & Holidays, 6AM-10PM.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Hyrum State Park",
    passType: "Pedestrian / Cyclist",
    altDescription: "Buy a Utah State Park Pass Walking or Biking",
    image: "individual.png",
    description: "Day-Use Pass: 6AM-10PM.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Jordan River OHV State Park",
    passType: "Rider",
    altDescription: "Jordan River Rider Day-Use pass",
    image: "ohv.png",
    description:
      "Day-Use Pass: Youth operators must be at least eight years of age and possess a valid education certificate.",
    price: "35.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Jordan River OHV State Park",
    passType: "50 Track Rider",
    altDescription: "Jordan River Rider Day-Use pass",
    image: "ohv.png",
    description:
      "If you purchase a wristband for the 50 track, you will not be able to ride the other tracks for that day.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  // Jordanelle (May-Oct)

  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    passType: "Vehicle",
    altDescription: "Hailstone Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    passType: "Senior (65+)",
    altDescription: "Hailstone Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    passType: "Pedestrian / Cyclist",
    altDescription: "Hailstone Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    passType: "Vehicle",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    passType: "Senior (65+)",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    passType: "Pedestrian / Cyclist",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    passType: "Vehicle",
    altDescription: "Ross Creek Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    passType: "Senior (65+)",
    altDescription: "Ross Creek Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    passType: "Pedestrian / Cyclist",
    altDescription: "Ross Creek Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Crandall Point Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "05/01",
    end_date: "11/01",
  },
  // Jordanelle (All year round) * REPEATED *

  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    passType: "Commercial Passenger",
    altDescription: "Hailstone Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: true,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    passType: "Commercial Passenger",
    altDescription:
      "Rock Cliff Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: true,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    passType: "Commercial Passenger",
    altDescription:
      "Ross Creek Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: true,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    passType: "Vehicle",
    altDescription: "Crandall Point Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    passType: "Senior (65+)",
    altDescription: "Crandall Point Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    passType: "Commercial Passenger",
    altDescription:
      "Crandall Point Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: true,
    start_date: "01/01",
    end_date: "12/31",
  },
  // Jordanelle (Nov-Dec) Off season

  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Hailstone Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Hailstone Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Hailstone Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Ross Creek Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Ross Creek Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Ross Creek Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Crandall Point Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  // Jordanelle (Jan - Apr)

  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Hailstone Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Fee: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Hailstone Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Fee: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Hailstone",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Hailstone Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Fee: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Fee: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Rock Cliff",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Rock Cliff Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "Ross Creek Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Fee: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Senior (65+)",
    altDescription: "Ross Creek Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Fee: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Ross Creek",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription: "Ross Creek Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  {
    name: "Jordanelle State Park",
    location: "Crandall Point",
    season: "Off-Season",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Crandall Point Buy a Utah State Park Pass Biking or Walking",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "04/30",
  },
  //end of jordanelle
  {
    name: "Kodachrome Basin State Park",
    passType: "Vehicle",
    altDescription: "Kodachrome Basin Buy a Utah State Park Pass Vehicle",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Kodachrome Basin State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Kodachrome Basin Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Kodachrome Basin State Park",
    passType: "Commercial Passenger",
    altDescription:
      "Kodachrome Basin Buy a Utah State Park Pass Tour Group Passenger",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 8 people.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: true,
  },

  {
    name: "Lost Creek State Park",
    passType: "Vehicle",
    altDescription:
      "Purchase a day-use pass to Lost Creek State Park to paddle, fish, swim, or camp in the area around the reservoir.",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Lost Creek State Park",
    passType: "Camping",
    altDescription:
      "Day-Use Pass and Campground Fee per night (includes 1 vehicle).",
    image: "camping.png",
    description:
      "Day-Use Pass and Campground Fee per night (includes 1 vehicle).",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Millsite State Park",
    passType: "Vehicle",
    altDescription: "Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Millsite State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy a Utah State Park Pass Walking",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Millsite State Park",
    passType: "Pedestrian/Cyclist",
    altDescription: "Buy a Utah State Park Pass Pedestrian / Cyclist",
    image: "individual.png",
    description: "Day-Use Pass: per person",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Otter Creek State Park",
    passType: "Vehicle",
    altDescription: "Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Otter Creek State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "8.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },

  {
    name: "Palisade State Park",
    passType: "Vehicle",
    altDescription: "Palisade Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "03/01",
    end_date: "11/30",
  },
  {
    name: "Palisade State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Palisade Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "03/01",
    end_date: "11/30",
  },
  //   Palisade off season
  {
    name: "Palisade State Park",
    passType: "Vehicle",
    season: "Off-Season",
    altDescription: "Palisade Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "12/01",
    end_date: "12/31",
  },
  {
    name: "Palisade State Park",
    passType: "Senior Resident (65+)",
    season: "Off-Season",
    altDescription: "Palisade Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "12/01",
    end_date: "12/31",
  },
  {
    name: "Palisade State Park",
    passType: "Camping",
    altDescription: "Palisade Buy a Utah State Park Pass",
    image: "camping.png",
    description:
      "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "12/01",
    end_date: "12/31",
  },
  {
    name: "Palisade State Park",
    passType: "Vehicle",
    season: "Off-Season",
    altDescription: "Palisade Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "02/28",
  },
  {
    name: "Palisade State Park",
    passType: "Senior Resident (65+)",
    season: "Off-Season",
    altDescription: "Palisade Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "02/28",
  },
  {
    name: "Palisade State Park",
    passType: "Camping",
    altDescription: "Palisade Buy a Utah State Park Pass",
    image: "camping.png",
    description:
      "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "02/28",
  },
  {
    name: "Piute State Park",
    passType: "Vehicle",
    altDescription: "Piute Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Piute State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Piute Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "3.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },

  {
    name: "Quail Creek State Park",
    passType: "Vehicle Resident",
    altDescription: "Quail Lake Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Utah state resident.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Quail Creek State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Quail Lake Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Quail Creek State Park",
    passType: "Vehicle Non-Resident",
    altDescription: "Quail Buy a Utah State Park Pass Non-Resident Vehicle",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Out of state visitor.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Quail Creek State Park",
    passType: "Pedestrian / Cyclist",
    altDescription: "Quail Buy a Utah State Park Pedestrian / Cyclist",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Quail Creek State Park",
    passType: "Night Fishing (9:00 p.m. - 7:00 a.m.)",
    altDescription: "Quail Buy a Utah State Park Pass Visiting",
    image: "fishing.png",
    description: "Night-Use Pass: per vehicle.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Red Fleet State Park",
    passType: "Vehicle",
    altDescription: "Red Fleet Buy a Utah State Park Pass Local",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Red Fleet State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Red Fleet Buy a Utah State Park Pass Senior Deal",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Red Fleet State Park",
    passType: "Commercial Passenger",
    altDescription: "Red Fleet Buy a Utah State Park Pass Commercial Passenger",
    image: "com-passenger.png",
    description: "Day-Use Pass: per person.",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Red Fleet State Park",
    passType: "Pedestrian/Cyclist",
    altDescription: "Red Fleet Buy a Utah State Park Pass Tour Group Passenger",
    image: "individual.png",
    description: "Day-Use Pass: per person.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  // Apr 16th - Oct 14th
  {
    name: "Rockport State Park",
    passType: "Vehicle",
    altDescription: "Buy your Vehicle Rockport State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Rockport State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Vehicle Senior (65+) Rockport State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  {
    name: "Rockport State Park",
    passType: "Highway 32 Parking Fee",
    altDescription: "Buy your Walk-in Rockport State Park Pass here",
    image: "individual.png",
    description: "Day-Use Pass: Highway 32 Parking and Walk in Lake Access",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  // Oct 15th - Dec 31st
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Vehicle",
  //     altDescription: "Buy your Vehicle Rockport State Park Pass here",
  //     image: "vehicle.png",
  //     description: "Off-Season Discount: per vehicle up to 8 people.",
  //     price: "10.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "10/15",
  //     end_date: "12/31",
  //   },
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Senior Resident (65+)",
  //     altDescription:
  //       "Buy your Vehicle Senior (65+) Rockport State Park Pass here",
  //     image: "senior.png",
  //     description: "Off-Season Discount: per vehicle up to 8 people.",
  //     price: "7.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "10/15",
  //     end_date: "12/31",
  //   },
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Highway 32 Parking Fee",
  //     altDescription: "Buy your Walk-in Rockport State Park Pass here",
  //     image: "individual.png",
  //     description:
  //       "Off-Season Discount: Highway 32 Parking and Walk in Lake Access",
  //     price: "4.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "10/15",
  //     end_date: "12/31",
  //   },
  // Jan 1st - Apr 15th
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Vehicle",
  //     altDescription: "Buy your Vehicle Rockport State Park Pass here",
  //     image: "vehicle.png",
  //     description: "Off-Season Discount: per vehicle up to 8 people.",
  //     price: "10.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "01/01",
  //     end_date: "04/15",
  //   },
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Senior Resident (65+)",
  //     altDescription:
  //       "Buy your Vehicle Senior (65+) Rockport State Park Pass here",
  //     image: "senior.png",
  //     description: "Off-Season Discount: per vehicle up to 8 people.",
  //     price: "7.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "01/01",
  //     end_date: "04/15",
  //   },
  //   {
  //     name: "Rockport State Park",
  //     season: "Off-Season",
  //     passType: "Highway 32 Parking Fee",
  //     altDescription: "Buy your Walk-in Rockport State Park Pass here",
  //     image: "individual.png",
  //     description:
  //       "Off-Season Discount: Highway 32 Parking and Walk in Lake Access",
  //     price: "4.00",
  //     days: 1,
  //     plate: true,
  //     personCount: false,
  //     start_date: "01/01",
  //     end_date: "04/15",
  //   },
  {
    name: "Sand Hollow State Park",
    passType: "Vehicle Non-Resident",
    altDescription:
      "Buy your vehicle Non-Resident Sand Hollow State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Sand Hollow State Park",
    passType: "Vehicle Resident",
    altDescription:
      "Buy your Vehicle Resident Sand Hollow State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Sand Hollow State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident (65+) Sand Hollow State Park Pass here",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Sand Hollow State Park",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Buy your Pedestrian / Cyclist Sand Hollow State Park Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Scofield State Park",
    passType: "Vehicle",
    altDescription: "Buy your Vehicle Scofield State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Scofield State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Buy your Senior Resident (65+) Echo State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },

  {
    name: "Snow Canyon State Park",
    passType: "Vehicle Resident",
    altDescription:
      "Buy your Vehicle Resident Snow Canyon State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Snow Canyon State Park",
    passType: "Senior Resident (65+)",
    altDescription:
      "Buy your Senior Resident (65+) Snow Canyon State Park Pass here",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Snow Canyon State Park",
    passType: "Pedestrian / Cyclist",
    altDescription:
      "Buy your Pedestrian / Cyclist Snow Canyon State Park Pass here",
    image: "individual.png",
    description: "Day-Use Pass: per person. For residents or non-residents.",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Snow Canyon State Park",
    passType: "Vehicle Non-Resident",
    altDescription:
      "Buy your Vehicle Non-Resident Snow Canyon State Park Pass here",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Snow Canyon State Park",
    passType: "Commercial Passenger",
    altDescription:
      "Buy your Commercial Passenger Snow Canyon State Park Pass here",
    image: "com-passenger.png",
    description:
      "Day-Use Pass: per individual of vehicles with more than 9 people.",
    price: "4.00",
    days: 1,
    plate: false,
    personCount: true,
  },

  {
    name: "Steinaker State Park",
    passType: "Vehicle",
    altDescription: "Steinaker State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Steinaker State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Steinaker State Park Pass",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
  },
  {
    name: "Steinaker State Park",
    passType: "Commercial Passenger",
    altDescription: "Steinaker State Park Pass",
    image: "com-passenger.png",
    description: "Day-Use Pass: per person",
    price: "3.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Steinaker State Park",
    passType: "Pedestrian/Cyclist",
    altDescription: "Steinaker State Park Pass",
    image: "individual.png",
    description: "Day-Use Pass: per person",
    price: "5.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  {
    name: "Territorial Statehouse State Park Museum",
    passType: "Individual",
    altDescription: "Territorial Statehouse State Park Museum Pass",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "2.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Territorial Statehouse State Park Museum",
    passType: "Senior Resident (65+)",
    altDescription: "Territorial Statehouse State Park Museum Pass",
    image: "senior.png",
    description: "Day-Use Pass: per individual 65 and older Utah resident.",
    price: "1.00",
    days: 1,
    plate: false,
    personCount: false,
    resident: true,
  },

  //   {"name":"Test Park", "passType": "Vehicle", "altDescription":"Buy a Utah State Park Pass The Great Salt Lake ", "image": "vehicle.png", "description": "Day-Use Pass: per vehicle up to 8 people", "price": "5.00", "days": 1, "plate": false, "personCount": false},
  //   {"name":"Test Park", "passType": "Senior Resident (65+)", "altDescription":"Buy a Utah State Park Pass Senior Deal The Great Salt Lake", "image": "senior.png", "description": "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.", "price": "3.00", "days": 1, "plate": false, "personCount": false, "resident": true},
  //   {"name":"Test Park", "passType": "Commercial Vehicle", "altDescription":"Buy a Utah State Park Pass Tour Group Passenger The Great Salt Lake", "image": "com-vehicle.png", "description": "Day-Use Pass: per vehicle over 8 people", "price": "20.00", "days": 1, "plate": false, "personCount": true},

  {
    name: "Utah Field House of Natural History State Park Museum",
    passType: "Adult",
    altDescription:
      "Utah Field House of Natural History State Park Museum Pass",
    image: "individual.png",
    description: "Day-Use Pass: per adult",
    price: "8.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Utah Field House of Natural History State Park Museum",
    passType: "Senior Resident (65+)",
    altDescription:
      "Utah Field House of Natural History State Park Museum Pass",
    image: "senior.png",
    description: "Day-Use Pass: per Utah state resident 65 and older.",
    price: "4.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Utah Field House of Natural History State Park Museum",
    passType: "Child (6-12)",
    altDescription:
      "Utah Field House of Natural History State Park Museum Pass",
    image: "child.png",
    description:
      "Day-Use Pass: per child. Five years of age and under are free.",
    price: "4.00",
    days: 1,
    plate: false,
    personCount: false,
  },

  // Apr - Oct
  {
    name: "Utah Lake State Park",
    passType: "Vehicle",
    altDescription: "Utah Lake State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. April-October.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Utah Lake State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Utah Lake State Park Pass",
    image: "senior.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    resident: true,
    start_date: "04/01",
    end_date: "10/31",
  },
  // Nov - Dec
  {
    name: "Utah Lake State Park",
    season: "Off-Season",
    passType: "Winter Vehicle",
    altDescription: "Utah Lake State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. November-March.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  // Jan - Mar
  {
    name: "Utah Lake State Park",
    season: "Off-Season",
    passType: "Winter Vehicle",
    altDescription: "Utah Lake State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. November-March.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "03/31",
  },
  // All Year
  {
    name: "Utah Lake State Park",
    passType: "Pedestrian / Cyclist",
    altDescription: "Utah Lake State Park Pass",
    image: "individual.png",
    description: "Day-Use Pass: per individual. All Year.",
    price: "4.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },

  //   {"name":"Utahraptor State Park", "passType": "Vehicle Resident", "altDescription":"Buy your Vehicle Resident Utahraptor State Park Pass here", "image": "vehicle.png", "description": "Day-Use Pass: per vehicle up to 8 people.", "price": "10.00", "days": 1, "plate": false, "personCount": false, "resident": true},
  //   {"name":"Utahraptor State Park", "passType": "Senior Resident (65+)", "altDescription":"Buy your Senior Resident (65+) Utahraptor State Park Pass here", "image": "senior.png", "description": "Day-Use Pass: per vehicle up to 8 people. 65 and older Utah resident.", "price": "5.00", "days": 1, "plate": false, "personCount": false, "resident": true},
  //   {"name":"Utahraptor State Park", "passType": "Pedestrian / Cyclist", "altDescription":"Buy your Pedestrian / Cyclist Utahraptor State Park Pass here", "image": "individual.png", "description": "Day-Use Pass: per group up to 8 people. For residents or non-residents.", "price": "5.00", "days": 1, "plate": false, "personCount": false},
  //   {"name":"Utahraptor State Park", "passType": "Vehicle Non-Resident", "altDescription":"Buy your Vehicle Non-Resident Utahraptor State Park Pass here", "image": "vehicle.png", "description": "Day-Use Pass: per vehicle up to 8 people.", "price": "15.00", "days": 1, "plate": false, "personCount": false},
  //   {"name":"Utahraptor State Park", "passType": "Commercial Passenger", "altDescription":"Buy your Commercial Passenger Utahraptor State Park Pass here", "image": "com-passenger.png", "description": "Day-Use Pass: per individual of vehicles with more than 9 people.", "price": "3.00", "days": 1, "plate": false, "personCount": true},

  {
    name: "Utahraptor State Park",
    passType: "Camping Pass/Vehicle",
    altDescription:
      "Nightly Camping Pass: per vehicle up to 8 people. Expires the following day of purchase at 2 p.m.",
    image: "camping.png",
    description:
      "Nightly Camping Pass: per vehicle up to 8 people. Expires the following day of purchase at 2 p.m.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  {
    name: "Wasatch Mountain State Park",
    passType: "Vehicle",
    altDescription: "Wasatch Mountain State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Wasatch Mountain State Park",
    passType: "Senior Resident (65+)",
    altDescription: "Wasatch Mountain State Park Pass",
    image: "senior.png",
    description: "Day-Use Pass: per vehicle up to 8 people. 65 and older.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
  },

  // Easter - Oct
  {
    name: "Willard Bay State Park",
    passType: "Mon-Thurs Vehicle",
    altDescription: "Willard Bay State Park",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Peak season is May-Oct.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Friday Vehicle",
    altDescription: "Willard Bay State Park",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Peak season is May-Oct.",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Sunday, Saturday, & Holiday Vehicle",
    altDescription: "Willard Bay State Park",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Peak season is May-Oct.",
    price: "30.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Mon-Thurs Senior Resident (65+)",
    altDescription: "Willard Bay State Park",
    image: "senior.png",
    description:
      "Day-Use Pass: per Utah state resident 65 and older. Peak season is May-Oct.",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Friday Senior Resident (65+)",
    altDescription: "Willard Bay State Park",
    image: "senior.png",
    description:
      "Day-Use Pass: per Utah state resident 65 and older. Peak season is May-Oct.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Sunday, Saturday, & Holiday Senior Resident (65+)",
    altDescription: "Willard Bay State Park",
    image: "senior.png",
    description:
      "Day-Use Pass: per Utah state resident 65 and older. Peak season is May-Oct.",
    price: "25.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "04/01",
    end_date: "10/31",
  },
  {
    name: "Willard Bay State Park",
    passType: "Pedestrian / Cyclist",
    altDescription: "Willard Bay State Park",
    image: "individual.png",
    description: "Day-Use Pass: per individual",
    price: "10.00",
    days: 1,
    plate: false,
    personCount: false,
    start_date: "01/01",
    end_date: "12/31",
  },
  // {
  //   name: "Willard Bay State Park",
  //   passType: "Pelican Group",
  //   altDescription: "Willard Bay State Park",
  //   image: "pelican.svg",
  //   description: "Day-Use Pass: per group",
  //   price: "150.00",
  //   days: 1,
  //   plate: false,
  //   personCount: false,
  //   start_date: "01/01",
  //   end_date: "12/31",
  // },
  // {
  //   name: "Willard Bay State Park",
  //   passType: "Eagle Group",
  //   altDescription: "Willard Bay State Park",
  //   image: "eagle.svg",
  //   description: "Day-Use Pass: per group",
  //   price: "200.00",
  //   days: 1,
  //   plate: false,
  //   personCount: false,
  //   start_date: "01/01",
  //   end_date: "12/31",
  // },
  // Oct - Dec
  {
    name: "Willard Bay State Park",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "imgdescription",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Off-season is Nov-Apr.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },
  {
    name: "Willard Bay State Park",
    season: "Off-Season",
    passType: "Senior Resident (65+)",
    altDescription: "imgdescription",
    image: "individual.png",
    description:
      "Day-Use Pass: per Utah state resident 65 and older. Off-season is Nov-Apr.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "11/01",
    end_date: "12/31",
  },

  // Dec - Easter
  {
    name: "Willard Bay State Park",
    season: "Off-Season",
    passType: "Vehicle",
    altDescription: "imgdescription",
    image: "vehicle.png",
    description:
      "Day-Use Pass: per vehicle up to 8 people. Off-season is Nov-Apr.",
    price: "10.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "03/31",
  },
  {
    name: "Willard Bay State Park",
    season: "Off-Season",
    passType: "Senior Resident (65+)",
    altDescription: "imgdescription",
    image: "individual.png",
    description:
      "Day-Use Pass: per Utah state resident 65 and older. Off-season is Nov-Apr.",
    price: "5.00",
    days: 1,
    plate: true,
    personCount: false,
    start_date: "01/01",
    end_date: "03/31",
  },

  {
    name: "Yuba State Park",
    passType: "Vehicle Mon-Thurs",
    altDescription: "Yuba State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people.",
    price: "15.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  {
    name: "Yuba State Park",
    passType: "Vehicle Fri-Sun & Holiday",
    altDescription: "Yuba State Park Pass",
    image: "vehicle.png",
    description: "Day-Use Pass: per vehicle up to 8 people. ",
    price: "20.00",
    days: 1,
    plate: true,
    personCount: false,
  },
  // {
  //   name: "Yuba State Park",
  //   passType: "Extra Vehicle Fee per night",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description: "Each additional vehicle per campsite, per night.",
  //   price: "25.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Yuba State Park",
  //   passType: "Oasis Campground",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description:
  //     "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
  //   price: "45.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Yuba State Park",
  //   passType: "North Beaches Camping",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description:
  //     "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
  //   price: "30.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Yuba State Park",
  //   passType: "Oasis Vista Camping",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description:
  //     "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
  //   price: "30.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Yuba State Park",
  //   passType: "Eagle View Camping",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description:
  //     "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
  //   price: "30.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Yuba State Park",
  //   passType: "Painted Rock Camping",
  //   altDescription: "Yuba State Park Pass",
  //   image: "vehicle.png",
  //   description:
  //     "Day-Use Pass and Campground Fee per day (includes 1 vehicle).",
  //   price: "30.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },

  {
    name: "Physical Annual Pass",
    passType: "Utah State Resident",
    altDescription: "Purchase an annual pass to Utah State Parks",
    image: "annual.png",
    description: "Pass expires 12 months from purchase date.",
    price: "125.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  {
    name: "Physical Annual Pass",
    passType: "Senior Utah State Resident",
    altDescription: "Purchase an annual pass to Utah State Parks",
    image: "senior.png",
    description: "65 and older. Pass expires 12 months from purchase date.",
    price: "65.00",
    days: 1,
    plate: false,
    personCount: false,
  },
  // {
  //   name: "Physical Annual Pass",
  //   passType: "Non-Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "annual.png",
  //   description: "Pass expires December 31st, 2023.",
  //   price: "175.00",
  //   days: 1,
  //   plate: false,
  //   personCount: false,
  // },

  // {
  //   name: "Digital Annual Pass",
  //   passType: "Utah State Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "annual.png",
  //   description: "Pass expires December 31st, 2023.",
  //   price: "125.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Digital Annual Pass",
  //   passType: "Senior Utah State Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "senior.png",
  //   description: "65 and older. Pass expires December 31st, 2023.",
  //   price: "65.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Digital Annual Pass",
  //   passType: "Non-Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "annual.png",
  //   description: "Pass expires December 31st, 2023.",
  //   price: "175.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },

  // {
  //   name: "Annual Pass",
  //   passType: "Utah State Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "annual.png",
  //   description: "Pass expires December 31st, 2023.",
  //   price: "125.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Annual Pass",
  //   passType: "Senior Utah State Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "senior.png",
  //   description: "65 and older. Pass expires December 31st, 2023.",
  //   price: "65.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
  // {
  //   name: "Annual Pass",
  //   passType: "Non-Resident",
  //   altDescription: "Purchase an annual pass to Utah State Parks",
  //   image: "annual.png",
  //   description: "Pass expires December 31st, 2023.",
  //   price: "175.00",
  //   days: 1,
  //   plate: true,
  //   personCount: false,
  // },
];
