<template>
  <div style="background-color: #fff; height: 100vh">
    <!-- Your HTML content goes here -->
    <Header />
    <div id="hero"></div>
    <div id="center">
      <div id="box-cont">
        <h1>Pick up Sticker at a Utah State Park</h1>
        <h2>How it works:</h2>
        <p>1. Arrive to any Utah State Park</p>
        <p>
          2. Show the email we sent to you from Parkspass to an employee to
          receive your sticker.
        </p>
        <p>3. Enjoy your visit!</p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/SocialFooter";
import $ from "jquery";

export default {
  name: "StickerLanding",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      type: "",
      barcode: "",
      fname: "",
      lname: "",
      email: "",
      phone: "",
      order_number: "",
      order_details: {},
    };
  },
  methods: {
    Search() {
      console.log("Searching for pass");
      console.log("type: ", this.type);
      console.log("barcode: ", this.barcode);
      console.log("fname: ", this.fname);
      console.log("lname: ", this.lname);
      console.log("email: ", this.email);
      console.log("phone: ", this.phone);

      var goit = false;
      // try to get it with the barcode
      var settings = {
        url: `https://trailwaze.info/parkspass/api/getPassExpirationDate.php?barcode=${this.barcode}`,
        method: "GET",
        timeout: 0,
        headers: {
          PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
        },
      };
      var _this = this;
      $.ajax(settings).done(function (response) {
        console.log(response);
        if (response.response == "SUCCESS") {
          goit = true;
          console.log("got it with barcode");
          _this.order_details = response;
          console.log(_this.order_details);
          // put in local storage and go to next page
          localStorage.setItem(
            "order_details",
            JSON.stringify(_this.order_details)
          );
          _this.$router.push("/passhelp/options");
        } else {
          console.log("failed to get it with barcode");
        }
      });

      // try to get it with the ordernumber if they bought it online
      if (this.type == "two" && !goit) {
        var settings2 = {
          url: `https://trailwaze.info/parkspass/api/getPassExpirationDate.php?order_number=${this.order_number}`,
          method: "GET",
          timeout: 0,
          headers: {
            PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
          },
        };

        $.ajax(settings2).done(function (response) {
          console.log(response);
          if (response.response == "SUCCESS") {
            goit = true;
            console.log("got it with order number");
            _this.order_details = response;
            console.log(_this.order_details);
            // put in local storage and go to next page
            localStorage.setItem(
              "order_details",
              JSON.stringify(_this.order_details)
            );
            _this.$router.push("/passhelp/options");
          } else {
            console.log("failed to get it with order number");
          }
        });
      }
    },
  },
  // Your component's logic goes here
};
</script>

<style scoped>
#hero {
  background-image: url("../../assets/images/pickup-big.png");
  height: 380px;
  background-size: cover;
  align-content: center;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
p {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}
#box-cont {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
}
#center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#grey-box {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  width: 60%;
  padding: 25px;
  margin-bottom: 100px;
}
#radio-cont {
  display: flex;
  margin-top: 20px;
  flex-direction: row !important;
}
.radio {
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px;
  margin-right: 10px;
}
.radio > label {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.radio-item {
  margin-right: 20px;
}
.circle {
  margin-right: 10px;
}
.radio-text {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}
.side-flex {
  display: flex;
  justify-content: space-between;
}
.down-flex {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.personal-input {
  width: 100%;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  padding-left: 16px;
  border-bottom: 1px solid #8f8f8f;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 20px;
}
h3 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
textarea:focus,
input:focus {
  outline: none;
}
.green-button {
  border-radius: 23px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 10px 18px;
}
#button-flex {
  display: flex;
  justify-content: flex-end;
}
/* Your component's styles go here */
@media (max-width: 425px) {
  /* Your styles for screens smaller than 425px go here */
  /* For example: */
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
  h2 {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
  #box-cont {
    margin-top: 30px;
  }
  #hero {
    background-image: url("../../assets/images/pickup.png");
    height: 180px;
  }
  #center {
    padding: 0px 24px;
  }
}
</style>
<style>
.v-input--radio-group__input {
  width: unset !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  padding-left: 10px;
}
</style>
