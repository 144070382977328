<template>
  <div style="background-color: #fff; height: 100vh">
    <!-- Your HTML content goes here -->
    <Header />
    <div v-if="success" class="spacing">
      <h1>Thanks for picking up your sticker!</h1>
    </div>
    <div v-if="fail" class="spacing">
      <h1>It looks like we have already given you a sticker.</h1>
      <h2>Reach out to parksupport@parkspass.org for help</h2>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/SocialFooter";
import $ from "jquery";

export default {
  name: "StickerLanding",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      order_details: {},
      monthName: "",
      ordernumber: "",
      success: false,
      fail: false,
    };
  },
  created() {
    this.ordernumber = this.$route.params.ordernumber;
    console.log(this.ordernumber);
    // get pass info
    this.passinfo();
  },
  methods: {
    passinfo() {
      var settings = {
        url: `https://trailwaze.info/test/parkpass/inventoryQRCode.php?user_key=ymWAfUH1LWNQzrOJyo5V0ml7&park_id=ANNUAL`,
        method: "GET",
        timeout: 0,
      };
      var _this = this;
      $.ajax(settings).done(function (response) {
        console.log(response);
        for (var i in response.tickets) {
          if (response.tickets[i].order_number == _this.ordernumber) {
            _this.order_details = response.tickets[i];
            console.log(_this.order_details);
            _this.activity();
          }
        }
      });
    },
    activity() {
      // check the check_in_activity  and see if its been to STICKR
      // Split the check_in_activity string by commas
      const activities = this.order_details.check_in_activity.split(",");

      // Check if any activity includes 'STICKR'
      const hasStickr = activities.some((activity) =>
        activity.includes("STICKR")
      );

      // Log or return the result
      if (hasStickr) {
        console.log("Checked in to STICKR park");
        // tell them they already have recieved their sticker reach out to us for help.
        this.fail = true;
      } else {
        console.log("No check-in to STICKR park found");
        // check them in and show success.
        this.success = true;
        this.checkin();
      }
    },
    checkin() {},
  },
  // Your component's logic goes here
};
</script>

<style scoped>
.spacing {
  margin: 50px 25px;
}
#hero {
  background-image: url("../../assets/images/pickup-big.png");
  height: 380px;
  background-size: cover;
  align-content: center;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 10px;
}
p {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 50px;
}
#box-cont {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
}
#center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#grey-box {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  width: 60%;
  padding: 25px;
  margin-bottom: 100px;
}
#radio-cont {
  display: flex;
  margin-top: 20px;
  flex-direction: row !important;
}
.radio {
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px;
  margin-right: 10px;
}
.radio > label {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.radio-item {
  margin-right: 20px;
}
.circle {
  margin-right: 10px;
}
.radio-text {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}
.side-flex {
  display: flex;
  justify-content: space-between;
}
.down-flex {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.personal-input {
  width: 100%;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  padding-left: 16px;
  border-bottom: 1px solid #8f8f8f;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 20px;
}
h3 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
textarea:focus,
input:focus {
  outline: none;
}
.green-button {
  border-radius: 23px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 10px 18px;
}
#button-flex {
  display: flex;
  justify-content: flex-end;
}
/* Your component's styles go here */
@media (max-width: 425px) {
  /* Your styles for screens smaller than 425px go here */
  h1 {
    font-size: 32px;
    line-height: 32px;
  }
  h2 {
    font-size: 18px;
  }
  #center {
    padding: 0px 24px;
  }
}
</style>
<style>
.v-input--radio-group__input {
  width: unset !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  padding-left: 10px;
}
</style>
