<template>
  <div style="background-color: #fff">
    <!-- Your HTML content goes here -->
    <Header />
    <div id="content">
      <div id="container">
        <h6>Your pass expires on the last day of {{ monthName }}.</h6>
        <h1>Receive your Sticker:</h1>
        <div id="card-cont">
          <v-card>
            <img src="../../assets/images/pickup.png" alt="" />
            <h2>Free Option:</h2>
            <p style="padding-left: 20px">
              Pick up your sticker at a Utah State Park.
            </p>
            <div class="button-flex">
              <button
                class="green-button"
                @click="redirect('/passhelp/pickup')"
              >
                Pick up My Sticker
              </button>
            </div>
          </v-card>
          <v-card>
            <img src="../../assets/images/order.png" alt="" />
            <h2>Expedited Shipping Option:</h2>
            <p style="padding-left: 20px">
              Order your sticker online through expediting shipping. Shipping
              fees apply.
            </p>
            <div class="button-flex">
              <button class="blue-button" @click="redirect('/passhelp/order')">
                Order Sticker Onlines
              </button>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/SocialFooter";
// import $ from "jquery";

export default {
  name: "StickerLanding",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      order_details: {},
      monthName: "",
    };
  },
  created() {
    // Your logic goes here
    this.order_details = JSON.parse(localStorage.getItem("order_details"));
    this.order_details = this.order_details.tickets[0];
    console.log(this.order_details);
    this.getExpiration();
  },
  methods: {
    getExpiration() {
      const expirationDate = new Date(this.order_details.date_expires);
      const lastDayOfMonth = new Date(
        expirationDate.getFullYear(),
        expirationDate.getMonth() + 1,
        0
      );
      const lastMonth = lastDayOfMonth.getMonth() + 1;
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[lastMonth - 1];
      console.log(lastMonth, monthName);
      this.monthName = monthName;
    },
    redirect(path) {
      this.$router.push(path);
    },
  },
  // Your component's logic goes here
};
</script>

<style scoped>
#hero {
  background-image: url("../../assets/images/Stickers.jpg");
  height: 380px;
  background-size: cover;
  align-content: center;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 20px 0px 0px 20px;
}
p {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
h6 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
#container {
  width: 52%;
}
#card-cont {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.v-card {
  width: 360px;
}
.green-button {
  border-radius: 23px;
  background: #96a145;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 18px;
}
.blue-button {
  border-radius: 23px;
  background: #4f8ab3;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 18px;
}
.button-flex {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 15px;
}
@media (max-width: 425px) {
  /* Your styles for screens smaller than 425px go here */
  #container {
    width: 90%;
  }
  #card-cont {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  #card-cont .v-card {
    margin-bottom: 20px;
  }
  #card-cont .v-card img {
    width: 100%;
  }
  h1 {
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
